import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useCurrentAccount, useAssertCanManage, useCurrentPerson } from 'modules/admin/hooks'
import { useCallApi, useCursoredDataEffect } from 'modules/common/hooks'
import { Api } from 'typescript-fetch-api'
import { useController, Formalities } from 'formalities'
import ImageUploader from 'react-images-upload'
import { adminRoleToName } from 'modules/admin/functions'
import { Route, useRouteMatch, useHistory } from 'react-router'
import BasicActionModal from 'modules/common/components/BasicActionModal'
import { blobToArrayBuffer, arrayBufferToBlob, blobToBase64String } from 'blob-util'
import resizer from 'image-resizer-js'
import { useDispatch, useSelector } from 'react-redux'
import { chooseMembership, setCurrentMembership } from 'modules/admin/actions'
import { selectCurrentMembership } from 'modules/admin/selectors'
import LoadingInline from 'modules/common/components/LoadingInline'
import { apiErrorToMessage } from 'modules/api/functions'
import { Link } from 'react-router-dom'
import MyReactQuill from 'modules/common/components/MyReactQuill'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface AddUserForm {
	givenName?: string
	familyName?: string
	email?: string
	userRole?: Api.AdminUserRole
}

const Settings: React.FC = function() {
	const account = useCurrentAccount()
	const person = useCurrentPerson()
	const callApi = useCallApi()
	const { url } = useRouteMatch()
	const history = useHistory()
	const controller = useController<Api.PatchAccountRequest>({
		name: account.name,
		logo: undefined,
		riskContactEmail: account.riskContactEmail,
		disabled: account.disabled,
		removeCompletedParticipations: account.removeCompletedParticipations,
		privacyStatement: account.privacyStatement,
		supportArticle: account.supportArticle,
		numberOfStaff: account.numberOfStaff,
		archived: account.archived,
	})
	const addUserController = useController<AddUserForm>({})
	const [newLogo, setNewLogo] = React.useState<File | undefined>(undefined)
	const { response, refresh } = useCursoredDataEffect((cursor) => callApi(api => api.accountApi.getUsers(account.id, cursor)), [account.id, callApi])
	const dispatch = useDispatch()
	const currentMembership = useSelector(selectCurrentMembership)
	useAssertCanManage()

	async function onUpdateSettings(evt: React.FormEvent) {
		evt.preventDefault()
		const accountDetails = { ...controller.snapshot().value }

		if (newLogo) {
			const logoArrayBuffer = await resizer(await blobToArrayBuffer(newLogo), { maxWidth: 800, maxHeight: 800 })
			const logoString = await blobToBase64String(await arrayBufferToBlob(logoArrayBuffer))
			accountDetails.logo = logoString
		}

		if (account.numberOfStaff && !accountDetails.numberOfStaff) {
			accountDetails.numberOfStaff = null
		}

		try {
			await callApi(api => api.accountApi.patchAccount(account.id, accountDetails))
			uniqueToastSuccess('Changes saved')

			/* Refresh account */
			if (currentMembership) {
				dispatch(chooseMembership(currentMembership))
			}
		} catch (error) {
			uniqueToastError(`Unable to save changes.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onUpdateUserRole(evt: React.ChangeEvent<HTMLSelectElement>, userId: string) {
		evt.preventDefault()
		let role = Api.AdminUserRole.View
		if (evt.target.value === 'manage') {
			role = Api.AdminUserRole.Manage
		} else if (evt.target.value === 'edit') {
			role = Api.AdminUserRole.Edit
		} else if (evt.target.value === 'report') {
			role = Api.AdminUserRole.Report
		} 

		try {
			await callApi(api => api.accountApi.updateUser(account.id, userId, { adminRole: role }))
			refresh()
			uniqueToastSuccess('Changes saved')
		} catch (error) {
			uniqueToastError(`Unable to save changes.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onAddUser(evt: React.FormEvent) {
		evt.preventDefault()
		const addUser = addUserController.snapshot().value
		
		if (addUser.email && addUser.givenName && addUser.familyName && addUser.userRole) {
			try {
				await callApi(api => api.accountApi.addUsers(account.id, { users: [{
					givenName: addUser.givenName!,
					familyName: addUser.familyName!,
					emailAddress: addUser.email!,
					adminRole: addUser.userRole!,
				}] }))
				addUserController.snapshot().setValue({})
				refresh()
				uniqueToastSuccess('User added')
			} catch (error) {
				uniqueToastError(`Unable to add user.\n${apiErrorToMessage(error)}`)
			}
		} else {
			uniqueToastError('Please complete all fields')
		}
	}
	
	function onOpenRemoveUserModal(evt: React.MouseEvent, userToRemove: string) {
		evt.preventDefault()
		history.push(`${url}/remove/${userToRemove}`)
	}

	async function onRemoveUser(userToRemove?: string) {
		if (userToRemove) {
			try {
				await callApi(api => api.accountApi.removeUser(account.id, userToRemove))
				refresh()
				uniqueToastSuccess('User removed')
			} catch (error) {
				uniqueToastError(`Unable to remove user.\n${apiErrorToMessage(error)}`)
			}
			history.push(`${url}`)
		}
	}

	function onChangeLogo(files: File[]) {
		if (files.length) {
			setNewLogo(files[0])
		} else {
			setNewLogo(undefined)
		}
	}

	function onShowRemoveImageModal(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(`${url}/remove-logo`)
	}

	async function onRemoveImage() {
		if (currentMembership) {
			try {
				await callApi(api => api.accountApi.patchAccount(account.id, { logo: null }))
				dispatch(setCurrentMembership({
					account: { 
						...account,
						logo: undefined,
					},
					role: currentMembership?.role,
					adminUserRole: currentMembership?.adminUserRole,
				}))
				history.push(`${url}`)
				uniqueToastSuccess('Changes saved')
			} catch (error) {
				uniqueToastError(`Unable to save changes.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	return (
		<Screen title="Settings" class="-narrow" includePageHeader={false}>
			<div className="content-header">
				<h1 className="headline-text">{account.name}'s settings</h1>
			</div>

			<form>
				<div className="form-field -adjacent">
					<label className="label">Name</label>
					<div className="form-input -text">
						<Formalities.Text className="field" controller={controller} prop="name" />
					</div>
				</div>				
				<div className="form-field -adjacent">
					<label className="label">Number of staff</label>
					<div className="form-input -text">
						<Formalities.Number className="field" controller={controller} prop="numberOfStaff" />
					</div>
				</div>
				<div className="form-field -adjacent">
					<label className="label">Logo</label>
					<div className="form-input -text">
						{account.logo && !newLogo && (
							<div className="logo">
								<div className="remove">
									<Link to="/" className="icon-text -close" onClick={onShowRemoveImageModal}></Link>
								</div>
								<div className="aspect" style={{ paddingTop: ((account.logo.height / account.logo.width) * 100) + '%' }}>
									<img src={account.logo.url} alt={account.name} width="100%" />
								</div>
							</div>
						)}
						<ImageUploader
							withIcon={true}
							buttonText='Choose file'
							singleImage={true}
							onChange={onChangeLogo}
							imgExtension={['.jpg', '.gif', '.png', '.gif']}
							label="JPG, PNG or GIF format at least 600px wide"
							maxFileSize={5242880}
							withPreview={true}
						/>
					</div>
				</div>
				{person.superAdmin && (
					<>
						<div className="form-field -stacked -guttered">
							<label className="label -large">High Psychological Distress Score Notification</label>
							<div className="form-input -text">
								<Formalities.Text className="field" controller={controller} prop="riskContactEmail" placeholder="hello@example.com" />
								<p className="note">The email address to which notifications of high psychological distress scores will be sent. Only the super administrator can see or set this email address.</p>
							</div>
						</div>
						<div className="form-field -stacked -guttered">
							<label className="label -large">Default Privacy Statement</label>
							<div className="form-input -text">
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									value={controller.snapshot().value.privacyStatement || ''}
									onChange={(content, delta, sources, editor) => {
										controller.snapshot('privacyStatement').setValue(editor.getHTML())
									}} />
								<p className="note">The default privacy statement to use for surveys for this organisation. Overrides the privacy statement for the survey template.</p>
							</div>
						</div>
						<div className="form-field -stacked -guttered">
							<label className="label -large">Default Support Article</label>
							<div className="form-input -text">
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									value={controller.snapshot().value.supportArticle || ''}
									onChange={(content, delta, sources, editor) => {
										controller.snapshot('supportArticle').setValue(editor.getHTML())
									}} />
								<p className="note">The default support article to use for surveys for this organisation.</p>
							</div>
						</div>
						<div className="form-field -stacked -guttered">
							<div className="form-input">
								<ul className="option-inputs">
									<li className="option -checkbox">
										<label className="label -bold">
											<Formalities.Checkable type="checkbox" controller={controller} prop="disabled" className="checkbox" checkedValue={true} uncheckedValue={false} />
											<span className="substitute"></span>
										Admin disabled
										</label>
									</li>
								</ul>
								<p className="note">If ticked, administrators will not be able to access this organisation. Only the super administrator can see or set this.</p>
							</div>
						</div>
						<div className="form-field -stacked -guttered">
							<div className="form-input">
								<ul className="option-inputs">
									<li className="option -checkbox">
										<label className="label -bold">
											<Formalities.Checkable type="checkbox" controller={controller} prop="removeCompletedParticipations" className="checkbox" checkedValue={true} uncheckedValue={false} />
											<span className="substitute"></span>
											Allow removal of completed participations from open surveys
										</label>
									</li>
								</ul>
								<p className="note">If ticked, users with Manage permissions will be able to remove completed participations and their data from open surveys. Only the super administrator can remove participations from closed surveys.</p>
							</div>
						</div>
						<div className="form-field -stacked -guttered">
							<div className="form-input">
								<ul className="option-inputs">
									<li className="option -checkbox">
										<label className="label -bold">
											<Formalities.Checkable type="checkbox" controller={controller} prop="archived" className="checkbox" checkedValue={true} uncheckedValue={false} />
											<span className="substitute"></span>
										Archived
										</label>
									</li>
								</ul>
							</div>
						</div>
					</>
				)}
				<div className="footer-actions">
					<div className="button-group -right">
						<input type="submit" className="button-link -action" value="Save changes" onClick={onUpdateSettings} />
					</div>
				</div>
				<div className="form-field -text">
					<h2 className="subheading-text">User management</h2>
				</div>
				<div className="form-field -table -nobottompadding">
					<table className="data-table -small permissions-table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Role</th>
								<th className="rightalign">Actions</th>
							</tr>
						</thead>
						<tbody>
							{response.loading && (
								<tr>
									<td colSpan={4}>
										<LoadingInline />
									</td>
								</tr>
							)}
							{
								response.result?.users.map((user, index) => (
									<tr key={index}>
										<td className="username">{user.name}</td>
										<td className="email">{user.emailAddress}</td>
										<td className="permissions">
											<div className="form-input -select permissions">
												<select className="select -small" value={user.adminRole} onChange={(e) => onUpdateUserRole(e, user.id)}>
													{
														Object.values(Api.AdminUserRole).map((value, index) => 
															<option key={index} value={value}>{adminRoleToName(value)}</option>,
														)
													}
												</select>
											</div>
										</td>
										<td className="remove rightalign"><button className="icon-unit -remove" onClick={(e) => onOpenRemoveUserModal(e, user.id)}>Remove</button></td>
									</tr>
								))
							}
						</tbody>
					</table>
				</div>
				<div className="form-field -text -nobottompadding">
					<h3 className="ui-heading">Add user</h3>
				</div>
				<div className="form-field -adduser">
					<div className="form-input -text name">
						<Formalities.Text className="field" controller={addUserController} prop="givenName" placeholder="Given name"/>
					</div>
					<div className="form-input -text name">
						<Formalities.Text className="field" controller={addUserController} prop="familyName" placeholder="Family name"/>
					</div>
					<div className="form-input -text email">
						<Formalities.Text className="field" controller={addUserController} prop="email" placeholder="Email"/>
					</div>
					<div className="form-input -select permissions">
						<Formalities.Select controller={addUserController} prop="userRole" className="select"
							options={[undefined, ...Object.values(Api.AdminUserRole).map(value => value)]}
							display={t => t ? adminRoleToName(t) : 'Roles…'}
						/> 
					</div>
					<div className="form-input -button">
						<button className="button-link -action" onClick={onAddUser}>Add</button>
					</div>
				</div>
			</form>
			<div className="form-field -stacked -guttered">
				<h3 className="ui-heading">Roles</h3>
				<ul>
					<li><em>View</em> can view the organisation's surveys, participants and reports.</li>
					<li><em>Edit</em> can view and edit the organisation's surveys, participants and reports.</li>
					<li><em>Manage</em> can view and edit the organisation’s surveys, participants and reports, as well as manage organisation settings and users.</li>
				</ul>
			</div>
			<Route path={`${url}/remove/:id`} render={(route) => 
				<BasicActionModal
					title="Remove user"
					text="Are you sure you want to remove this user?"
					buttonAction={onRemoveUser}
					buttonText="Remove user"
					className="dialog-modal"
					{...route}
				/>} 
			/>
			<Route path={`${url}/remove-logo`} render={(route) => 
				<BasicActionModal
					title="Remove logo"
					text="Are you sure you want to remove this logo?"
					buttonAction={onRemoveImage}
					buttonText="Remove logo"
					className="dialog-modal"
					{...route}
				/>} 
			/>
		</Screen>
	)
}

export default Settings
