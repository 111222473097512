import React, { useState, useCallback } from 'react'
import RingChartSet from 'modules/common/components/RingChartSet'
import { Api } from 'typescript-fetch-api'
import { useParams } from 'react-router'
import { pathToSurveyReport, pathToSurveyReportDetails } from '../navigation'
import { Link } from 'react-router-dom'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import ReportFilters from './ReportFilters'
import ReportComparisons from './ReportComparisons'
import LoadingInline from 'modules/common/components/LoadingInline'
import { useCallApi } from 'modules/common/hooks'
import produce from 'immer'
import { useCurrentPerson } from 'modules/admin/hooks'
import { useController } from 'formalities'
import ArcChart from 'modules/common/components/ArcChart'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'
import ReportCombination from './ReportCombination'
import AspectsReportArea from './AspectsReportArea'
import DataReportArea from './DataReportArea'
import ReportAreaAnalysis from './ReportAreaAnalysis'
import { ReportAreaAnalysisData } from 'modules/admin/types'

interface Props {
	report: Api.SurveyReport
	filters?: Api.GetFilters200Response
	reports?: Api.GetReports200Response
	availableCompareSurveys?: Api.SurveySummary[]
	request?: Api.ReportOptions
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
	section?: number
	printMode?: boolean
	setReport?: (report: Api.SurveyReport) => void
}

const ReportDetails: React.FC<Props> = function(props) {
	const params = useParams<{ surveyId: string; detailIndex: string }>()
	const sectionIndex = props.section !== undefined ? props.section : Number(params.detailIndex)
	const { report, request, setRequest } = props
	const person = useCurrentPerson()
	const callApi = useCallApi()
	const [isFilterPopoverOpen, setFilterPopoverOpen] = useState(false)
	const [isComparePopoverOpen, setComparePopoverOpen] = useState(false)
	const [isCombinePopoverOpen, setCombinePopoverOpen] = useState(false)
	const hideAnalysis = request && (request.compareToSurvey || request.compareToNormative || request.compareToFilter || request.filter || request.combineWithSurveys) ? true : false
	const section = report.sections[sectionIndex]
	const compareToSurvey = request && request.compareToSurvey && props.reports ? props.reports.reports.find(s => s.survey.id === request.compareToSurvey) : undefined
	const combiningSurveys = request && request.combineWithSurveys && report.combinedSurveys ? report.combinedSurveys : undefined

	const analysisTextController = useController<ReportAreaAnalysisData[]>(section.areas.map((area, index) => ({
		id: index,
		text: area.analysis,
		disabled: true,
	})))
	const analysisText = analysisTextController.snapshot().value

	function onSetRequest(newRequest: Api.ReportOptions | undefined) {
		setRequest(newRequest)
		setFilterPopoverOpen(false)
		setComparePopoverOpen(false)
		setCombinePopoverOpen(false)
	}

	function onRemoveQuestionFilter(questionId: string, answerIndex: number) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.filter || !draft.filter.questions) {
				return draft
			}
			const i = draft.filter.questions.findIndex(q => q.question === questionId)
			if (i !== -1) {
				const j = draft.filter.questions[i].answers.indexOf(answerIndex)
				if (j !== -1) {
					draft.filter.questions[i].answers.splice(j, 1)
				}
			}
		}))
	}

	function onRemoveParticipantGroupFilter(participantGroupId: string) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.filter || !draft.filter.participantGroups) {
				return draft
			}

			const i = draft.filter.participantGroups.indexOf(participantGroupId)
			if (i !== -1) {
				draft.filter.participantGroups.splice(i, 1)
			}
		}))
	}

	function onRemoveCompareToSurvey() {
		setRequest(produce(request, draft => {
			if (!draft) {
				return draft
			}

			draft.compareToSurvey = undefined
			draft.compareToNormative = false
		}))
	}

	function onRemoveCombinedSurvey(surveyId: string) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.combineWithSurveys) {
				return draft
			}
			const i = draft.combineWithSurveys.indexOf(surveyId)
			if (i !== -1) {
				draft.combineWithSurveys.splice(i, 1)

				if (draft.combineWithSurveys.length === 0) {
					draft.combineWithSurveys = undefined
				}
			}
		}))
	}

	const onAnalysisChange = useCallback((index: number, text?: string) => {
		const newArr = [...analysisText]
		newArr[index] = {
			id: index,
			text: text,
			disabled: false,
		}
		analysisTextController.snapshot().setValue(newArr)
	}, [analysisText, analysisTextController])

	const disableSaveButton = useCallback((disable: boolean, index: number) => {
		const newArr = [...analysisText]
		newArr[index] = {
			...newArr[index],
			disabled: disable,
		}
		analysisTextController.snapshot().setValue(newArr)
	}, [analysisText, analysisTextController])

	const saveAreaAnalysis = useCallback(async function(evt: React.FormEvent, index: number, areaId: string) {
		evt.preventDefault()
		disableSaveButton(true, index)

		try {
			const response = await callApi(api => api.surveyApi.patchSurveyReport(params.surveyId, {
				sections: [{ id: section.id, areas: [{ id: areaId, analysis: analysisText[index].text }] }] },
			))
			props.setReport && props.setReport(response.report)
			uniqueToastSuccess('Successfully updated analysis')
		} catch (error) {
			uniqueToastError('Failed to update executive analysis')
		}
	}, [analysisText, callApi, disableSaveButton, params.surveyId, section.id, props])

	const renderAreaAnalysis = useCallback((area: Api.SurveyReportArea, areaIndex: number) => {
		return (
			<ReportAreaAnalysis 
				area={area} 
				areaIndex={areaIndex} 
				hideAnalysis={hideAnalysis} 
				analysisText={analysisText} 
				onAnalysisChange={onAnalysisChange} 
				saveAreaAnalysis={saveAreaAnalysis} 
				superAdmin={person.superAdmin} 
				printMode={props.printMode}
			/>
		)
	}, [analysisText, hideAnalysis, onAnalysisChange, person.superAdmin, props.printMode, saveAreaAnalysis])

	return (
		<>
			<div className="report-header">
				<div className="header">
					<p className="subheading-text _no-print">{report.survey.name}</p>
					<h1 className="headline-text -hero">{section.name}</h1>
					<p className="options _no-print">
						<Popover 
							isOpen={isFilterPopoverOpen}
							position={'bottom'}
							content={({ position, targetRect, popoverRect }) => (
								<ArrowContainer
									position={position}
									targetRect={targetRect}
									popoverRect={popoverRect}
									arrowColor={'white'}
									arrowSize={10}
									arrowStyle={{ opacity: 1 }}
								>
									{props.filters ? (
										<ReportFilters filters={props.filters} request={props.request} setRequest={onSetRequest} />
									) : (
										<LoadingInline />
									)}
								</ArrowContainer>
							)}
							onClickOutside={() => setFilterPopoverOpen(false)}
						><button className="icon-text -filter" onClick={() => setFilterPopoverOpen(!isFilterPopoverOpen)}>Filter</button></Popover>
						<Popover 
							isOpen={isComparePopoverOpen}
							position={'bottom'}
							content={({ position, targetRect, popoverRect }) => (
								<ArrowContainer
									position={position}
									targetRect={targetRect}
									popoverRect={popoverRect}
									arrowColor={'white'}
									arrowSize={10}
									arrowStyle={{ opacity: 1 }}
								>
									{props.reports ? (
										<ReportComparisons reports={props.reports} request={props.request} setRequest={onSetRequest} surveyToCompareId={report.survey.id}/>
									) : (
										<LoadingInline />
									)}
								</ArrowContainer>
							)}
							onClickOutside={() => setComparePopoverOpen(false)}
						><button className="icon-text -compare _no-print" onClick={() => setComparePopoverOpen(!isComparePopoverOpen)}>Compare</button></Popover>
						<Popover 
							isOpen={isCombinePopoverOpen}
							position={'bottom'}
							content={({ position, targetRect, popoverRect }) => (
								<ArrowContainer
									position={position}
									targetRect={targetRect}
									popoverRect={popoverRect}
									arrowColor={'white'}
									arrowSize={10}
									arrowStyle={{ opacity: 1 }}
								>
									{props.reports ? (
										<ReportCombination reports={props.reports} request={props.request} setRequest={onSetRequest} currentSurveyId={report.survey.id} currentSurveyTemplateId={report.survey.template?.id}/>
									) : (
										<LoadingInline />
									)}
								</ArrowContainer>
							)}
							onClickOutside={() => setCombinePopoverOpen(false)}
						><button className="icon-text -report-combine _no-print" onClick={() => setCombinePopoverOpen(!isCombinePopoverOpen)}>Combine</button></Popover>
					</p>
				</div>	
			</div>
			{request && (request.filter || (request.compareToSurvey || request.compareToNormative) || combiningSurveys) &&
				<div className="report-filtering">
					{request.filter &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Filters:</h3>
							{request.filter && request.filter.questions && request.filter.questions.map((q, questionIndex) => {
								const question = props.filters && props.filters.questions.find(fq => fq.id === q.question)
								return (
									<React.Fragment key={questionIndex}>
										<h3 className="body-text -small">{question ? question.title : 'Unknown'}</h3>
										<ul className="tag-set">
											{q.answers.map(answerIndex => (
												<li className="tag" key={answerIndex}>
													{question && question.type === Api.QuestionTypeEnum.Multi ? question.options[answerIndex] : '???'}
													<button className="remove" onClick={evt => {
														evt.preventDefault()
														onRemoveQuestionFilter(q.question, answerIndex) 
													}}>&multi;</button>
												</li>
											))}
										</ul>
									</React.Fragment>
								)
							})}
							{request.filter && request.filter.participantGroups && (
								<>
									<h3 className="body-text -small">Participant Groups</h3>
									<ul className="tag-set">
										{request.filter.participantGroups.map((participantGroupId, pgIndex) => {
											const participantGroup = props.filters && props.filters.participantGroups.find(pg => pg.id === participantGroupId)
											return (
												<li className="tag" key={pgIndex}>
													{participantGroup ? participantGroup.name : 'Unknown'}
													<button className="remove" onClick={evt => {
														evt.preventDefault()
														onRemoveParticipantGroupFilter(participantGroupId) 
													}}>&multi;</button>
												</li>
											)
										})}
									</ul>
								</>
							)}
							<button className="icon-unit -small -edit _no-print" onClick={() => setFilterPopoverOpen(true)}>Edit filters</button>
						</div>
					}
					{(request.compareToSurvey || request.compareToNormative) &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Comparing:</h3>
							<ul className="tag-set">
								<li className="tag">
									{compareToSurvey ? compareToSurvey.survey.name : request.compareToNormative ? 'Normative' : 'Unknown'}
									<button className="remove _no-print" onClick={evt => {
										evt.preventDefault()
										onRemoveCompareToSurvey()
									}}>&multi;</button>
								</li>
							</ul>
							<button className="icon-unit -small -edit _no-print" onClick={(evt) => {
								evt.preventDefault()
								setComparePopoverOpen(!isComparePopoverOpen) 
							}}>Edit comparison survey</button>
						</div>
					}
					{combiningSurveys &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Combining:</h3>
							<ul className="tag-set">
								{combiningSurveys.map((survey, index) => (
									<li key={'compareSurvey' + index} className="tag">
										{survey.name}
										<button className="remove _no-print" onClick={evt => {
											evt.preventDefault()
											onRemoveCombinedSurvey(survey.id)
										}}>&multi;</button>
									</li>
								))}
							</ul>
							<button className="icon-unit -small -edit _no-print" onClick={(evt) => {
								evt.preventDefault()
								setCombinePopoverOpen(!isCombinePopoverOpen) 
							}}>Edit combined surveys</button>
						</div>
					}
					<button className="clear _no-print" onClick={() => setRequest(undefined)}>Clear all</button>
				</div>
			}
			<div className="intro">
				<div className="body-text -large">
					<p>{section.description}</p>
				</div>
				<ArcChart 
					score={section.score} 
					reportName={report.survey.name}
					compareToScore={((compareToSurvey || request?.compareToNormative) && section.compareToScores) ? section.compareToScores[0] : undefined}
					showComparativeKeys={((compareToSurvey || request?.compareToNormative) && section.compareToScores) ? true : false}
					comparativeName={compareToSurvey ? compareToSurvey.survey.name : request?.compareToNormative ? 'Normative' : undefined}
				/>
			</div>
			{section.areas.map((area, index) => (
				area.areaType === 'aspects' ? (
					<div key={index} className="result-details">
						<div className="content">
							<div className="summary">
								<h2 className="headline-text -small">{area.name}</h2>
								<div className="body-text -large">
									<p>{area.description}</p>
								</div>
								{area.series[1] && renderComparisonKey(report.survey.name, area.series[1].name)}
							</div>
							{renderMeanChart(area)}
							{area.groups && (renderIconGroups(area))}
						</div>
						<div className="results-block">
							<AspectsReportArea area={area} report={report} />
						</div>
						<div className="body-text -large">
							{renderAreaArticle(area)}
							{renderAreaAnalysis(area, index)}
						</div>
					</div>
				) : area.areaType === 'data' ? (
					<div key={index} className="result-details">
						<div className="content">
							<div className="summary">
								<h2 className="headline-text -small">{area.name}</h2>
								<div className="body-text -large">
									<p>{area.description}</p>
								</div>
								{area.series[1] && renderComparisonKey(report.survey.name, area.series[1].name)}
							</div>
							{renderMeanChart(area)} 
						</div>
						<div className="results-block">
							<DataReportArea area={area} report={report} printMode={!!props.printMode} />
						</div>
						<div className="body-text -large">
							{renderAreaArticle(area)}
							{renderAreaAnalysis(area, index)}
						</div>
					</div>
				) : area.areaType === 'content' ? (
					<div key={index} className="result-details">
						<div className="content">
							<div className="summary">
								<h2 className="headline-text -small">{area.name}</h2>
								<div className="body-text -large">
									<p>{area.description}</p>
								</div>
							</div>
						</div>
						<div className="results-block">
							<div className="body-text -large -guttered" dangerouslySetInnerHTML={{ __html: area.content }}></div>
						</div>
						<div className="body-text -large">
							{renderAreaArticle(area)}
							{renderAreaAnalysis(area, index)}
						</div>
					</div>
				) : (
					null
				)
			))}
			<div className="button-group -centered _no-print">
				{sectionIndex > 0 && 
					<Link to={pathToSurveyReportDetails(params.surveyId, sectionIndex - 1)} className="button-link -action -prev">{report.sections[sectionIndex - 1].name}</Link>
				}
				<Link to={pathToSurveyReport(params.surveyId)} className="button-link -action">Results overview</Link>
				{sectionIndex < (report.sections.length - 1) && 
					<Link to={pathToSurveyReportDetails(params.surveyId, sectionIndex + 1)} className="button-link -action -next">{report.sections[sectionIndex + 1].name}</Link>
				}
			</div>
		</>
	)
}

function renderAreaArticle(area: Api.SurveyReportArea) {
	if (area.article) {
		return (
			<>
				<h2 className="subheading-text">{area.article.name}</h2>
				{area.article.contents.map((content, index) => {
					if (content.contentType === 'Html') {
						return <div key={index} dangerouslySetInnerHTML={{ __html: content.html }}/>
					} else {
						return undefined
					}
				})}
			</>
		)
	} else {
		return null
	}
}

function renderComparisonKey(reportName: string, comparisonName?: string) {
	return (
		<div className="comparison-key">
			<svg width="100" height="10" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<linearGradient id="keyGradientDefault">
						<stop offset="5%" stopColor="#D73F38" />
						<stop offset="45%" stopColor="#F1DC00" />
						<stop offset="55%" stopColor="#F1DC00" />
						<stop offset="95%" stopColor="#50B555" />
					</linearGradient>
					<linearGradient id="keyGradientComparison">
						<stop offset="5%" stopColor="#5F5F5F" />
						<stop offset="45%" stopColor="#C5C4C4" />
						<stop offset="55%" stopColor="#C5C4C4" />
						<stop offset="95%" stopColor="#999999" />
					</linearGradient>
				</defs>
			</svg>
			<p className="key">
				<span className="indicator">
					<svg viewBox="0 0 100 10" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="0" width="100" height="10" />
					</svg>
				</span>
				{reportName}
			</p>
			{
				comparisonName &&
				<p className="key">
					<span className="indicator -comparison">
						<svg viewBox="0 0 100 10" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0" width="100" height="10" />
						</svg>
					</span>
					{comparisonName}
				</p>
			}
		</div>
	)
}

function renderMeanChart(area: Api.SurveyReportAspectsArea | Api.SurveyReportDataArea) {
	const comparison: (Api.SurveyReportAspectsArea.Series | Api.SurveyReportDataArea.Series)[] = area.series.slice(1)
	return (
		<div className="mean-results">
			<h3 className="body-text">Mean results</h3>
			<div className="charts">
				<RingChartSet poor={area.series[0].minRatio} good={area.series[0].midRatio} great={area.series[0].maxRatio} minLabel={area.minLabel} midLabel={area.midLabel} maxLabel={area.maxLabel}/>
			</div>
			{comparison.map((series, seriesIndex) => (
				<div className="charts" key={seriesIndex}>
					<RingChartSet 
						poor={series.minRatio} 
						good={series.midRatio} 
						great={series.maxRatio} 
						minLabel={area.minLabel} 
						midLabel={area.midLabel} 
						maxLabel={area.maxLabel}
						hideLabels={true}
						comparison={true}
					/>
				</div>
			))}
		</div>
	)
}

function renderIconGroups(area: Api.SurveyReportAspectsArea) {
	return (
		<>
			<div className="icon-results">
				{ area.groups?.map((group, index) => (
					<div key={index} className="icons">
						<h3 className="body-text">{group.title}</h3>
						<div className="icon-grid -unframed -horizontal -small">
							{
								area.series[0].aspects.map((aspect, index) => {
									if (aspect.group === group.code) {
										const imgSrc = aspect.icon ? require('modules/frontend/img/icon/' + aspect.icon + '.svg') : undefined
										return <li key={index} className="item"><div className="icon"><img src={imgSrc} className={'icon-svg ' + (aspect.icon ? (' -' + aspect.icon) : '')} alt={'Icon for ' + aspect.name}/><span className="label">{aspect.name}</span></div></li>
									}
									return null
								},
								)
							}
						</div>
					</div>
				))}
			</div>
			{
				area.series[1] && 
				<div className="icon-results -comparison">
					{ area.groups?.map((group, index) => (
						<div key={index} className="icons">
							<h3 className="body-text">{group.title}</h3>
							<div className="icon-grid -unframed -horizontal -small">
								{
									area.series[1].aspects.map((aspect, index) => {
										if (aspect.group === group.code) {
											const imgSrc = aspect.icon ? require('modules/frontend/img/icon/' + aspect.icon + '.svg') : undefined
											return <li key={index} className="item"><div className="icon"><img src={imgSrc} className={'icon-svg ' + (aspect.icon ? (' -' + aspect.icon) : '')} alt={'Icon for ' + aspect.name}/><span className="label">{aspect.name}</span></div></li>
										}
										return null
									},
									)
								}
							</div>
						</div>
					))}
				</div>
			}
		</>
	)
}

export default ReportDetails
